<template>
  <div id="event-doctors-form">
    <!-- <h1 class="mt-5">{{ $t("EVENT.EVENT_DETAILS.REGISTER_DOCTOR") }}</h1> -->
    <form class="mt-5">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="name"
              :placeholder="$t('EVENT.EVENT_DETAILS.FORM.FIRST_NAME')"
              v-model="doctor.firstName"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="name"
              :placeholder="$t('EVENT.EVENT_DETAILS.FORM.LAST_NAME')"
              v-model="doctor.lastName"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <!-- <label for="email">{{
              $t("EVENT.EVENT_DETAILS.FORM.EMAIL")
            }}</label> -->
            <input
              type="email"
              class="form-control"
              id="email"
              :placeholder="$t('EVENT.EVENT_DETAILS.FORM.EMAIL')"
              v-model="doctor.email"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <!-- <label for="mobile-no">{{
              $t("EVENT.EVENT_DETAILS.FORM.MOBILE_NUMBER")
            }}</label> -->
            <input
              type="text"
              class="form-control"
              id="mobile-no"
              :placeholder="$t('EVENT.EVENT_DETAILS.FORM.MOBILE_NUMBER')"
              v-model="doctor.mobileNo"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <v-autocomplete
              v-model="doctor.speciality"
              :items="specialities"
              item-value="value"
              :item-text="specialityLang"
              dense
              filled
              :label="$t('EVENT.EVENT_DETAILS.FORM.SPECIALITY')"
            ></v-autocomplete>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <v-autocomplete
              v-model="doctor.governorate"
              :items="governorates"
              item-value="value"
              :item-text="governorateLang"
              dense
              filled
              :label="$t('EVENT.EVENT_DETAILS.FORM.GOVERNORATE')"
            ></v-autocomplete>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <v-autocomplete
              v-model="doctor.visitedByNovonordisk"
              :items="options"
              item-value="value"
              :item-text="optionText"
              dense
              filled
              :label="$t('EVENT.EVENT_DETAILS.FORM.BEEN_VISITED')"
            ></v-autocomplete>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="address"
              :placeholder="$t('EVENT.EVENT_DETAILS.FORM.ADDRESS')"
              v-model="doctor.address"
            />
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button @click="goBack" class="btn btn-primary mr-2">
          {{ $t("COMMON.BACK") }}
        </button>
        <button
          @click.prevent="onSubmit"
          :disabled="isDisabled"
          class="btn btn-success"
        >
          {{ $t("COMMON.VERIFY") }}
        </button>
      </div>
    </form>
    <VerficationModal :vm="this" />
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import constants from "@/common/config/constants.json";
import VerficationModal from "@/components/VerficationModal";

export default {
  components: {
    VerficationModal,
  },
  methods: {
    ...mapActions({
      saveDoctor: "saveDoctor",
      sendOtp: "sendOtp",
      assigDoctorToEvent: "assigDoctorToEvent",
      checkDoctorByMobileNumber: "checkDoctorByMobileNumber",
    }),
    ...mapMutations({
      SET_DOCTOR: "SET_DOCTOR",
    }),
    onSubmit() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const doctor = {
        name: this.doctor.firstName + " " + this.doctor.lastName,
        email: this.doctor.email,
        address: this.doctor.address,
        mobileNo: this.doctor.mobileNo,
        speciality: this.doctor.speciality,
        governorate: this.doctor.governorate,
        verfied: false,
        invited: this.doctor.visitedByNovonordisk,
      };

      this.SET_DOCTOR(doctor);

      this.checkDoctorByMobileNumber({
        mobileNo: doctor.mobileNo,
        vm: this,
        addDoctor: true,
      });

      // this.sendOtp({
      //   phoneNumber: doctor.mobileNo,
      //   vm: this,
      // });

      // this.saveDoctor({
      //   vm: this,
      //   data: {
      //     doctor,
      //     byEvent: true,
      //     eventId: this.$route.params.id,
      //   },
      // });
    },
    goBack() {
      this.$emit("goBack");
    },
    resetFields() {
      this.doctor = {
        firstName: null,
        lastName: null,
        email: null,
        address: null,
        mobileNo: null,
      };
    },
  },
  data() {
    return {
      doctor: {
        firstName: null,
        lastName: null,
        email: null,
        address: null,
        mobileNo: null,
        speciality: null,
        governorate: null,
        visitedByNovonordisk: null,
      },
      loading: false,
      options: [
        { value: true, TextEn: "Yes", TextAr: "نعم" },
        { value: false, TextEn: "No", TextAr: "لا" },
      ],
    };
  },
  computed: {
    specialities: () => {
      return constants.specialities;
    },
    governorates() {
      return constants.governorates;
    },
    governorateLang() {
      if (localStorage.getItem("language") === "en")
        return "governorate_name_en";
      else return "governorate_name_ar";
    },
    specialityLang() {
      if (localStorage.getItem("language") === "en") return "TextEn";
      else return "TextAr";
    },
    optionText() {
      if (localStorage.getItem("language") === "en") return "TextEn";
      else return "TextAr";
    },
    isDisabled() {
      if (
        !this.doctor.firstName ||
        !this.doctor.lastName ||
        !this.doctor.email ||
        !this.doctor.mobileNo ||
        !this.doctor.speciality ||
        !this.doctor.governorate
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 12px;
  background-color: white;
  border: 1px solid;
  height: calc(3em + 1.3rem + 2px);
  border-radius: 15px;
  padding: 5px;
  padding-left: 30px;
  border-color: #a8a8a8;
}

::v-deep
  .theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  display: none;
}

::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0;
  display: none;
}

.form-group {
  margin-bottom: 1rem !important;
}

#event-doctors-form {
  h3 {
    font-weight: 500;
    font-size: 16px;
  }

  h1 {
    font-size: 20px;
    color: #3c4eba;
    font-weight: 500;
  }

  form {
    max-width: 600px;
    margin-inline: auto;

    input {
      border-radius: 15px;
      padding: 33px;
      border-color: #a8a8a8;
    }

    select {
      height: calc(3.5em + 1.3rem + 2px);
      border-radius: 15px;
      padding: 10px;
      padding-left: 30px;
      border-color: #a8a8a8;
    }

    button {
      width: 150px;
      border-radius: 12px;
      height: 55px;
      font-size: 18px;

      &.btn-primary {
        background-color: #21285f !important;
        border-color: #21285f !important;
      }
    }
  }
}
</style>
