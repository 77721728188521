<template>
  <div class="doctor-exist-section">
    <h2 class="helvetica">{{ event.eventText }}</h2>
    <div class="d-flex justify-content-center mt-4">
      <b-button @click="submitAnswer('yes')" class="btn-success mr-4">{{
        $t("COMMON.YES")
      }}</b-button>
      <b-button @click="submitAnswer('no')" class="btn-primary">{{
        $t("COMMON.NO")
      }}</b-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  methods: {
    ...mapActions({
      connect: "connect",
      send: "send",
    }),
    ...mapMutations({
      SET_DOCTOR_EXIST: "SET_DOCTOR_EXIST",
      SET_DOCTOR: "SET_DOCTOR",
    }),
    submitAnswer(answer) {
      this.SET_DOCTOR_EXIST(answer);
      this.connect();
      setTimeout(() => {
        this.send({ msg: "STARTING" });
      }, 2500);
    },
  },
  computed: {
    ...mapGetters({
      event: "event",
    }),
  },
};
</script>

<style lang="scss" scoped>
.doctor-exist-section {
  text-align: center;

  h2 {
    font-size: 35px;
    font-weight: 500;
    color: #21285f;
  }

  button {
    border-radius: 10px;
    width: 150px;

    &.btn-primary {
      background-color: #21285f !important;
      border-color: #21285f !important;
    }
  }
}
</style>
