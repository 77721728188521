<template>
  <div id="doctor-already-exist">
    <template v-if="!doctor">
      <form @submit.prevent="onSubmit">
        <!-- <label for="">Please enter your phone number</label> -->
        <input
          type="text"
          v-model="mobileNo"
          :placeholder="$t('EVENT.EVENT_DETAILS.FORM.ENTER_PHONE_NUMBER')"
          class="form-control"
        />
        <div class="d-flex justify-content-center mt-3">
          <b-button @click="goBack" type="button" class="btn-primary">{{
            $t("COMMON.BACK")
          }}</b-button>
          <b-button type="submit" class="btn-success ml-2">{{
            $t("COMMON.SUBMIT")
          }}</b-button>
        </div>
      </form>
    </template>
    <template v-else>
      <!-- <h2>Doctor Details</h2> -->
      <div class="head mt-5">
        <div class="left-side">
          <div class="">
            <label for="name">{{ $t("FIELDS.NAME") }}:</label
            ><span>{{ doctor.name }}</span>
          </div>
          <div class="">
            <label for="date">{{ $t("FIELDS.EMAIL") }}:</label>
            <span>{{ doctor.email }}</span>
          </div>
          <div class="">
            <label for="location">{{ $t("FIELDS.MOBILE_NUMBER") }}:</label>
            <span>{{ doctor.mobileNo }}</span>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-3">
        <b-button
          class="btn-primary"
          v-if="doctorInEvent"
          @click="goToNextPage"
          >{{ nextPageButton }}</b-button
        >
        <b-button v-else class="btn-success" @click="assignDoctor">{{
          $t("EVENT.EVENT_DETAILS.ASSIGN_TO_EVENT")
        }}</b-button>
      </div>
    </template>
    <template v-if="!doctor && submitted">
      <b-alert class="mt-5" show
        >This mobile Number doesn't exist please enter your number
        again</b-alert
      >
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      mobileNo: null,
      submitted: null,
    };
  },
  watch: {
    doctor() {
      if (this.doctor) {
        this.doctorExistInEvent({
          eventId: this.$route.params.id,
          doctorId: this.doctor.id,
        });
      }
    },
  },
  methods: {
    ...mapActions({
      getDoctorByMobileNumber: "getDoctorByMobileNumber",
      assigDoctorToEvent: "assigDoctorToEvent",
      doctorExistInEvent: "doctorExistInEvent",
    }),
    onSubmit() {
      this.submitted = true;
      this.getDoctorByMobileNumber({ mobileNo: this.mobileNo });
    },
    assignDoctor() {
      const doctorData = {
        id: this.doctor.id,
      };
      this.assigDoctorToEvent({
        doctorData,
        eventId: this.$route.params.id,
      });
    },
    goBack() {
      this.$emit("goBack");
    },
    goToNextPage() {
      if (
        this.event.hasQuestionnaire &&
        this.eventDoctor &&
        !this.eventDoctor.questionnaireSubmitted
      ) {
        this.$router.push({
          name: "Questionnaire",
          params: {
            doctorId: this.doctor.id,
            eventId: this.$route.params.id,
          },
        });
      } else if (
        this.event.hasPhoto &&
        this.eventDoctor &&
        !this.eventDoctor.photoPrinted
      ) {
        this.$router.push({
          name: "Select Frame",
          params: {
            doctorId: this.doctor.id,
            eventId: this.$route.params.id,
          },
        });
      } else if (
        this.event.hasVoucher &&
        this.eventDoctor &&
        !this.eventDoctor.voucherPrinted
      ) {
        this.$bvModal.show("voucher-modal");
      } else {
        this.$router.push({ name: "Thank You" });
      }
    },
  },
  computed: {
    ...mapGetters({
      doctor: "doctor",
      doctorInEvent: "doctorInEvent",
      event: "event",
      eventDoctor: "eventDoctor",
    }),
    nextPageButton() {
      if (
        this.event.hasQuestionnaire &&
        this.eventDoctor &&
        !this.eventDoctor.questionnaireSubmitted
      ) {
        return this.$t("PRINT_FORM.GO_TO_QUESTIONNAIRE");
      } else if (
        this.event.hasPhoto &&
        this.eventDoctor &&
        !this.eventDoctor.photoPrinted
      ) {
        return this.$t("EVENT.EVENT_DETAILS.TAKE_PHOTO");
      } else if (
        this.event.hasVoucher &&
        this.eventDoctor &&
        !this.eventDoctor.voucherPrinted
      ) {
        return this.$t("EVENT.EVENT_DETAILS.RECIEVE_VOUCHER");
      } else {
        return this.$t("COMMON.THANK_YOU");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 20px;
  color: #3c4eba;
  font-weight: 500;
}

form {
  input {
    border-radius: 15px;
    padding: 33px;
    border-color: #a8a8a8;
  }

  button {
    width: 150px;
    border-radius: 12px;
    height: 55px;
    font-size: 18px;

    &.btn-primary {
      background-color: #21285f !important;
      border-color: #21285f !important;
    }
  }
}

.head {
  border: 1px solid #3c4eba;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  label {
    font-weight: 500;
    font-size: 16px;
  }

  span {
    font-weight: 400;
    font-size: 18px;
    margin-left: 10px;
  }
}
</style>
