<template>
  <div>
    <b-modal
      hide-footer
      id="verfication-modal"
      :title="$t('EVENT.EVENT_DETAILS.VERFICATION_MODAL_TITLE')"
    >
      <form>
        <input
          v-for="(n, index) in code"
          :key="index"
          type="number"
          pattern="\d*"
          :id="'input_' + index"
          maxlength="1"
          v-model="code[index]"
          @input="handleInput"
          @keypress="isNumber"
          @keydown.delete="handleDelete"
          @paste="onPaste"
        />
      </form>
      <b-button @click="verify" class=" mt-4 btn-success w-100">{{
        $t("COMMON.VERIFY")
      }}</b-button>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    vm: {
      type: Object,
    },
  },
  data() {
    return {
      code: Array(6),
      dataFromPaste: undefined,
      keysAllowed: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    };
  },
  methods: {
    ...mapActions({
      verifyOtp: "verifyOtp",
    }),
    isNumber(event) {
      event.currentTarget.value = "";
      const keyPressed = event.key;
      if (!this.keysAllowed.includes(keyPressed)) {
        event.preventDefault();
      }
    },
    handleInput(event) {
      const inputType = event.inputType;
      let currentActiveElement = event.target;
      if (inputType === "insertText")
        currentActiveElement.nextElementSibling?.focus();
      if (inputType === "insertFromPaste" && this.dataFromPaste) {
        for (const num of this.dataFromPaste) {
          let id = parseInt(currentActiveElement.id.split("_")[1]);
          currentActiveElement.value = num;
          this.code[id] = num;
          if (currentActiveElement.nextElementSibling) {
            currentActiveElement = currentActiveElement.nextElementSibling;
            currentActiveElement.nextElementSibling?.focus();
          }
        }
      }
    },
    handleDelete(event) {
      //keydown event = move to previous element then only delete number
      let value = event.target.value;
      let currentActiveElement = event.target;
      if (!value) currentActiveElement.previousElementSibling?.focus();
    },
    onPaste(event) {
      this.dataFromPaste = event.clipboardData
        ?.getData("text")
        .trim()
        .split("");
      if (this.dataFromPaste) {
        for (const num of this.dataFromPaste) {
          if (!this.keysAllowed.includes(num)) event.preventDefault();
        }
      }
    },
    verify() {
      const data = {
        code: this.code.join(""),
        phoneNumber: this.doctor.mobileNo,
        // doctorId: this.doctor.id,
      };
      this.verifyOtp({
        vm: this.vm,
        data,
        event: this.event,
      });
    },
  },
  computed: {
    ...mapGetters({
      doctor: "doctor",
      event: "event",
    }),
  },
};
</script>

<style lang="scss" scoped>
#verfication-modal {
  form {
    display: flex;
    flex-direction: row;
    gap: 18px;
    flex-wrap: wrap;
    justify-content: center;
    direction: ltr !important;
  }
  input[type="number"] {
    height: 50px;
    text-align: center;
    border: 1px solid lightgray;
    border-radius: 5px;
    width: 40px;
    font-size: 27px;
    text-align: center;
    background-color: lightyellow;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  @media only screen and (max-width: 1080px) {
    input[type="number"] {
      width: 80px;
    }
  }
  @media only screen and (max-width: 600px) {
    input[type="number"] {
      width: 40px;
    }
  }
  @media only screen and (max-width: 500px) {
    form {
      gap: 8px;
    }
    input[type="number"] {
      width: 12vw;
      font-size: 40px;
    }
  }
}
</style>
