<template>
  <div id="event-details" class="h-100" v-if="event">
    <div class="d-flex justify-content-center mt-5 mb-5">
      <img class="logo img-fluid" src="@/assets/media/icons/logo.png" alt="" />
    </div>
    <div v-if="doctorExisted === null" class="event-data">
      <h1 class="helvetica">{{ event.eventName }}</h1>
      <div class="head mt-1">
        <div class="event-item mb-3">
          <img src="@/assets/media/icons/Event_Name.png" alt="" />
          <div
            :class="$i18n.locale === 'en' ? 'right-marg' : 'left-marg'"
            class="d-flex flex-column align-items-center"
          >
            <label class="helvetica" for="name">{{
              $t("EVENT.EVENT_NAME")
            }}</label
            ><span class="helvetica">{{ event.eventName }}</span>
          </div>
        </div>
        <div class="event-item mb-3">
          <img src="@/assets/media/icons/Event_Date.png" alt="" />
          <div
            :class="$i18n.locale === 'en' ? 'right-marg' : 'left-marg'"
            class="d-flex flex-column align-items-center"
          >
            <label class="helvetica" for="date">{{
              $t("EVENT.EVENT_DATE")
            }}</label>
            <span class="helvetica">{{ formatDate(event.eventDate) }}</span>
          </div>
        </div>
        <div class="event-item mb-3">
          <img src="@/assets/media/icons/Event_Location.png" alt="" />
          <div
            :class="$i18n.locale === 'en' ? 'right-marg' : 'left-marg'"
            class="d-flex flex-column align-items-center"
          >
            <label class="helvetica" for="location">{{
              $t("EVENT.EVENT_LOCATION")
            }}</label>
            <span class="helvetica">{{ event.location }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="event-body mt-5">
      <template v-if="roles.includes('ROLE_EVENT_MANAGER')">
        <div v-if="doctorExisted === null">
          <DoctorExist />
        </div>
        <div v-else-if="doctorExisted === false">
          <AddDoctorToEventForm @goBack="reset" />
        </div>
        <div v-else-if="doctorExisted === true">
          <DoctorAlreadyExist @goBack="reset" />
        </div>
      </template>
      <template v-else>
        <v-app>
          <b-tabs content-class="mt-3">
            <b-tab :title="$t('EVENT.EVENT_DETAILS.ADD_QUESTIONS')">
              <b-alert
                show
                v-if="
                  roles.includes('ROLE_ADMIN') &&
                    event.hasQuestionnaire &&
                    event.count > 0
                "
                >You cannot edit or add any questions to this event
                anymore</b-alert
              >
              <EventQuestions
                v-else-if="
                  roles.includes('ROLE_ADMIN') && event.hasQuestionnaire
                "
            /></b-tab>
          </b-tabs>
          <template></template>
        </v-app>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
// import EventDoctors from "@/components/events/EventDoctors.vue";
import EventQuestions from "@/components/events/EventQuestions.vue";
import DoctorExist from "@/components/events/DoctorExist.vue";
import AddDoctorToEventForm from "@/components/events/AddDoctorToEventForm.vue";
import DoctorAlreadyExist from "@/components/events/DoctorAlreadyExist.vue";

export default {
  created() {
    this.getEvent({ id: this.$route.params.id });
    this.SET_DOCTOR(null);
    this.SET_DOCTOR_EXIST(null);
  },
  components: {
    // EventDoctors,
    EventQuestions,
    DoctorExist,
    AddDoctorToEventForm,
    DoctorAlreadyExist,
  },
  data() {
    return {
      doctorExist: null,
    };
  },
  methods: {
    ...mapMutations({
      SET_DOCTOR: "SET_DOCTOR",
      SET_DOCTOR_EXIST: "SET_DOCTOR_EXIST",
    }),
    ...mapActions({
      getEvent: "getEvent",
    }),
    formatDate(date) {
      const today = new Date(date);
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      const formattedToday = dd + "-" + mm + "-" + yyyy;

      return formattedToday;
    },
    reset() {
      this.SET_DOCTOR_EXIST(null);
    },
  },
  computed: {
    ...mapGetters({
      event: "event",
      roles: "roles",
      doctorExisted: "doctorExisted",
    }),
  },
};
</script>

<style lang="scss" scoped>
#event-details {
  // background-color: #f5f5f5;

  // padding: 20px;

  // h1 {
  //   font-size: 20px;
  //   color: #3c4eba;
  //   font-weight: 500;
  // }

  // .head {
  //   border: 1px solid #3c4eba;
  //   padding: 10px;
  //   border-radius: 10px;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: flex-start;
  //   label {
  //     font-weight: 500;
  //     font-size: 16px;
  //   }

  //   span {
  //     font-weight: 400;
  //     font-size: 18px;
  //     margin-left: 10px;
  //   }
  // }

  .logo {
    width: 130px;
  }

  .event-data {
    padding: 12px 20px;
    padding-top: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
      rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    max-width: 700px;
    margin-inline: auto;
    border-radius: 12px;
    margin-top: 50px;
    h1 {
      font-weight: 500;
      font-size: 21px;
      color: #21285f;
      text-align: center;
    }

    .head {
      width: 100%;
    }

    .event-item {
      background-color: #f2f8fa;
      padding: 12px;
      border-radius: 12px;
      display: flex;
      align-items: center;

      > div {
        // margin-left: 100px;
        flex: 1;

        &.right-marg {
          margin-right: 80px;
        }

        &.left-marg {
          margin-right: 0;
          margin-left: 55px;
        }
      }

      img {
        width: 60px;
      }

      label {
        font-weight: 500;
        font-size: 16px;
        color: #424eaa;
      }

      span {
        font-weight: 500;
        font-size: 16px;
        color: #000000;
      }
    }
  }
}
</style>
